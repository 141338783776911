<template>
  <div class="row">
    <input-text
      v-model="filtro.descricao"
      class="col-12 col-md-6"
      :label="$t('modulos.tipo_setor.formulario.nome')"
      :placeholder="$t('modulos.tipo_setor.formulario.nome')"
      em-filtro
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        posicoes: helpers.TipoSetorEnum
      },
    };
  },
};
</script>
