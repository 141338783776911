<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.tipo_setor.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.tipo_setor.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('TipoSetores', 'Inserir')"
      sem-filtro
      @abrirNovo="abrirNovo"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('TipoSetores', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>

    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      :mostrar-seletor="buscarPermissao('TipoSetores', 'Deletar')"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)"
      @filtrarGlobal="listarRegistros"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          v-if="buscarPermissao('TipoSetores', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import TipoSetoresService from '@common/services/cadastros/TipoSetoresService.js';
import Filtro from "./filtro.vue";
import helpers from '@common/utils/helpers'
export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'descricao',
            text: this.$t('modulos.marca.tabela.nome'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'TipoSetores', 'Listar');
    const filtros = helpers.buscarStateFiltros(this);
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.tipo_setor.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(this, funcionalidade, funcionalidadeAcao);
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina) {
        this.tabela.porPagina = porPagina;
        helpers.salvarStateFiltros(this, {
          filtroAplicado: this.filtroAplicado,
          paginaAtual,
          porPagina,
      })
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      TipoSetoresService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];

        this.tabela.selecionados.forEach(item => {
          promises.push(TipoSetoresService.excluir(item.id))
        })
          Promise.all(promises).then(() => {
            this.listarRegistros();
            this.toastSucesso(this.$t(`modulos.tipo_setor.exclusao_sucesso`));
            this.tabela.selecionados = []
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'tipo-setores-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'tipo-setores-editar',
        params: { id: item.id },
      });
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
